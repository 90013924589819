@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@200,300;400;500;600&display=swap');

*{
  font-family: 'Barlow', sans-serif;
  font-weight: 300;
}

html, body { 
  scroll-behavior: smooth !important;
}
html:focus-within {
  scroll-behavior: smooth;
}
/* Default height for small devices */
#intro-example {
  height: 400px;
}

.intro .intro-video-cover {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    border: 0;
}

.navbar{
  z-index: 1;
}

.intro h1{
  font-size: 100px;
}

.nav-link{
  text-transform: uppercase;
  font-size: 13px !important;
  font-weight: 500 !important;
}

.intro {
  height: 100vh;
  overflow: hidden;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.navbar-collapse{
  background-color: rgba(0, 0, 0, 0.9);;
  margin-top: 10px;
}



.navbar-collapse img{
  margin: 0 0 10px 8px;
}

.menu {
  position: fixed;
  width: 100%;
  padding-right: 30px;
  padding-left: 30px;
  background-color: transparent;
  z-index: 999;
  transition: 0.5s ease;
  margin-top: 20px;
}

.menu-page {
  position: fixed;
  width: 100%;
  padding-right: 30px;
  padding-left: 30px;
  background-color: #000;
  z-index: 999;
  transition: 0.5s ease;
  margin-top: 20px;
}

.menu-scroll {
  position: fixed;
  width: 100%;
  padding-right: 30px;
  padding-left: 30px;
  background-color: #000;
  z-index: 999;
  transition: 0.5s ease;
}

.menu img{
  transform: scale(1);
  transition: 0.5s ease;
}

.menu-scroll img{
  transform: scale(.8);
  transition: 0.5s ease;
}

.menu .navbar-brand{
  margin-right: 20px;
  transition: 0.5s ease;
}

.menu-scroll .navbar-brand{
  margin-right: 0;
  transition: 0.5s ease;
}

h1, h2, h3, h4, h5, h6{
  font-weight: 400;
  z-index: 1;
}

/* .nosotros h4{
  transition: 0.3s;
  transition-delay: 0.3s;
}

.nosotros:hover h4{
  transform: translateY(0);
  opacity: 1;
} */
.trabajos-item{
  /* background-color: black; */
}

.trabajos-item img{
  transition: 1s ease;
}

.trabajos-item img:hover {
  -webkit-filter: blur(5px);
  transition: 1s ease;
}

.trabajos-item .hover-overlay{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-attachment: fixed;
}

.trabajos-item .trabajos-text{
  transition: 1s ease;
}

.trabajos-item .trabajos-text:hover{
  background-color: rgba(0, 0, 0, 0.8);
  transition: 1s ease;
}
.trabajos-item .trabajos-text h4{
  transform: translateY(-10px);
  transition: .3s;
  opacity: 0;
}

.trabajos-item .trabajos-text h6{
  transform: translateY(10px);
  transition: .3s;
  opacity: 0;
}

.trabajos-item .trabajos-text:hover h4{
  transform: translateY(0);
  transition: 1s;
  opacity: 1;
}

.trabajos-item .trabajos-text:hover h6{
  transform: translateY(0);
  transition: 1s;
  opacity: 1;
}

.trabajos-titulo, .trabajos-subtitulo{
  opacity: 1;
}

.trabajos-item .trabajos-text .btn{
  transform: translateY(10px);
  transition: .3s;
  opacity: 0;
}

.trabajos-item .trabajos-text:hover .btn{
  transform: translateY(0);
  transition: 1s;
  opacity: 1;
  transition-delay: 0.3s
}

.trabajos-back:before{
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  opacity: 0.3;
  background-image: url('assets/images/back-nosotros2.jpg');
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.servicios-back{
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  opacity: 1;
  background-image: url('assets/images/servicios-back.jpg');
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.servicios-back:before{
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  opacity: .7;
  background-color: #000;
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.servicios-cards{
  position: relative;
  top: -50px;
}

.servicios-cards .card{
  position: relative;
  top: -40px;
}

.servicios-cards i{
  position: relative;
  z-index: 1;
}
#ReactGridGallery{
  margin-top: 20px;;
}

/* Height for devices larger than 992px */
@media (min-width: 992px) {
  #intro-example {
    height: 600px;
  }
}

@media screen and (max-width: 600px) {
  .intro h1{
    font-size: 80px;
  }
  .intro{
    height: 400px;
  }
  .navbar-collapse .nav-link{
    padding : 8px 10px !important;
    border-bottom: 1px solid #333;
  }
}